<template>
  <!--begin::Clients-->
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <Clients
        route-to-create="partnerTenantsCreate"
        route-to-table="partnerTenants"
        route-to-details="partnerTenantsDetails"
        route-breadcrumb="/partner/tenants"
        :partner-area="true"
      />
    </div>
  </div>
  <!--end::Clients-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Clients from "@/components/Admins/Clients/Table";

export default {
  components: {
    Clients
  },
  data() {
    return {
      partnerId: null
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.partnerTenants"),
        route: ""
      },
      {
        title: this.$t("general.overview"),
        route: "/partner/tenants"
      }
    ]);
  }
};
</script>
